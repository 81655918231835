// 
// general.scss
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700,800&display=swap');

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}
