body.login {
  background-color: #f0f7fd;
  overflow: auto;
  padding-bottom: 0;
}

.login {
  .card {
    background-color: transparent;
    border: none;
  }

  .card-body {
    background: #aad7e8 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 18px #81818140;
    border-radius: 20px;
    opacity: 1;
  }

  .btn-primary {
    background-color: #035888;
    border-color: #035888;
    border-radius: 20px;
    padding: 12px 0;
  }

  form {
    label {
      color: #4d4d4d;
      font-weight: bold;
      font-size: 16px;
    }

    input {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 20px;
      border: none;
    }
  }

  .footer {
    position: static;

    

    .titulo {
      margin-top: 80px;
      margin-bottom: 40px;
      padding-bottom: 26px;
      color: #035888;

      &:after {
        content: '';
        height: 1px;
        width: 58%;
        background-color: #dbebf1;
        display: block;
        margin: auto;
        position: relative;
        top: 20px;
      }
    }

    .row {
      margin-bottom: 20px;

      img {
        height: 35px;
        width: initial;
        align-self: center;
      }
      img.huol {
        height: 20px;
      }
    }
  }

  .logo-header-login {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 480px) {
    .footer {
      bottom: inherit;
      padding-top: 60px;

      &:before {
        content: "";
        background-color: #dcdcdc;
        height: 1px;
        width: 155px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

      h4 {
        margin-bottom: 30px;
      }


      .row {
        img {
          height: 30px;
          display: block;
          margin: 12px auto;
        }
      }

      
    }
  }
}
